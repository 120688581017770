<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout wrap justify-center style="display: flex">
          <v-flex xs3 sm3 md3>
            <v-layout wrap justify-center align-center style="display: flex; margin-top: 15vh;">
              <v-flex xs12 sm12 md12>
                <div class="logo-login">
                  <img :src="logo" />
                </div>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-form ref="form">
                  <label class="label-modal">{{ $t("fields.username") }}</label>
                  <v-text-field
                    @keyup.enter.native="login"
                    :color="$primary"
                    :rules="[RULES.required]"
                    autocomplete="off"
                    prepend-icon="person"
                    type="text"
                    v-model="username"
                  ></v-text-field>
                  <label class="label-modal">{{ $t("fields.pwd") }}</label>
                  <v-text-field
                    @keyup.enter.native="login"
                    :color="$primary"
                    :rules="[RULES.required]"
                    prepend-icon="lock"
                    type="password"
                    v-model="password"
                  ></v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs12 sm12 md12 justify-end style="display: flex">
                <v-btn
                  v-on:click.stop="login()"
                  :color="$primary"
                  :loading="loading"
                  class="white--text"
                >{{ $t("actions.signin") }}</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <div class="year">
            <div>
              <span>
                &copy; {{ currentYear }} DRUBLOC -
                {{ $t("messages.allrights") }}
              </span>
            </div>
          </div>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import RULES from "@/mixins/_RULES"
export default {
  data: () => ({
    logo: require("@/assets/logo.png"),
    username: "",
    password: "",
    loading: false
  }),
  mixins: [RULES],
  computed: {
    ...mapGetters({
      getSession: "session/getState"
    }),
    currentYear() {
      return this.$moment().format("YYYY")
    }
  },
  created() {},
  methods: {
    login() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      this.$store
        .dispatch("session/signIn", {
          username: this.username,
          password: this.password
        })
        .then(res => {
          if (res) {
            this.$eventBus.$emit("login", this.getSession.session)
            this.loading = false
          } else {
            this.loading = false
            this.$toast.error(this.$t("messages.nologin"))
          }
        })
    }
  }
}
</script>

<style scoped>
.logo-login {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 5vh !important;
  position: relative;
}

.logo-login img {
  width: 50% !important;
  z-index: 3;
}

button {
  margin-right: 0 !important;
}

.animation-shap-login {
  width: 50%;
  position: absolute;
  height: 120%;
  top: 0%;
  right: 0%;
}

.year {
  position: absolute !important;
  bottom: 10px !important;
  font-weight: bold !important;
  display: flex !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.year div {
  display: flex !important;
  justify-content: center !important;
}
</style>
