import axios from "axios"
import qs from "qs"
import convert from "xml-js"

// initial state
const state = {
  data: [],
  isLoading: false
}

var call
// getters
const getters = {
  getAll: state => {
    return state.data
  },
  getById: state => id => {
    if (
      !id ||
      state.data.length === 0 ||
      typeof state.data.find(el => {
        return el.strcabid === id
      }) == "undefined"
    )
      return "-"
    return state.data.find(el => {
      return el.strcabid === id
    }).nome
  },
  getOrcamento: state => id => {
    if (
      !id ||
      state.data.length === 0 ||
      typeof state.data.find(el => {
        return el.strcabid === id
      }) == "undefined"
    )
      return "-"
    return state.data.find(el => {
      return el.strcabid === id
    })
  },
  isLoading: state => {
    return state.isLoading
  }
}

// actions
const actions = {
  get({ commit }, params) {
    if (call || params == null) {
      commit("setLoading", false)
      if (typeof call != "undefined") call.cancel()
      return
    }
    call = axios.CancelToken.source()
    commit("setLoading", true)
    return axios({
      method: "POST",
      crossDomain: true,
      ncelToken: call.token,
      async: true,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      data: qs.stringify({
        invocationPassword: params.pwd,
        clienteid: params.id
      }),
      url: `${params.url}/DrublocGetTender`
    })
      .then(res => {
        var result = JSON.parse(
          JSON.parse(
            convert.xml2json(res.data, {
              compact: true,
              spaces: 4
            })
          ).string._text
        )
        call = false
        commit("setLoading", false)
        commit("setData", result)
      })
      .catch(function(thrown) {
        // handle error
      })
  },

  getByCliente({ commit }, params) {
    return axios({
      method: "POST",
      crossDomain: true,
      async: true,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      data: qs.stringify({
        invocationPassword: params.pwd,
        clienteid: params.id
      }),
      url: `${params.url}/DrublocGetTender`
    }).then(res => {
      return JSON.parse(
        JSON.parse(
          convert.xml2json(res.data, {
            compact: true,
            spaces: 4
          })
        ).string._text
      )
    })
  }
}

// mutations
const mutations = {
  setData(state, data) {
    state.data = data ? data : []
  },
  setLoading(state, st) {
    state.isLoading = st
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
