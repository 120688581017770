import { instance as axios } from "@/store/_AXIOS"
import { _TOKEN, _DECODE } from "@/store/_JWT"

const TABLE = "/session/"
let isClicked = false

// initial state
const state = {
  session: false,
  user: null
}

// getters
const getters = {
  getState: function(state) {
    return state
  }
}

// actions
const actions = {
  getData({ commit, dispatch }, params) {
    return axios
      .post(TABLE + "get/", _TOKEN(params))
      .then(res => {
        if (res.data.results === 1) {
          return _DECODE(res.data.data).then(r => {
            if (r) {
              commit("setSessionData", r[0])
              return true
            } else {
              commit("setSessionData", null)
              return false
            }
          })
        } else {
          commit("setSessionData", null)
          return false
        }
      })
      .catch(err => {
        commit("setSessionData", null)
        return false
      })
  },

  signIn({ commit, dispatch }, params) {
    if (isClicked) return undefined
    isClicked = true

    setTimeout(() => {
      isClicked = false
    }, this._vm.$holdTime)
    return axios
      .post(TABLE + "login/", _TOKEN(params))
      .then(res => {
        if (res.data.results === 1) {
          return _DECODE(res.data.data).then(r => {
            if (r) {
              commit("setSessionData", r[0])
              return true
            } else {
              commit("setSessionData", null)
              return false
            }
          })
        } else {
          commit("setSessionData", null)
          return false
        }
      })
      .catch(err => {
        commit("setSessionData", null)
        return false
      })
  },

  update({ dispatch }, params) {
    if (isClicked) return undefined
    isClicked = true

    setTimeout(() => {
      isClicked = false
    }, this._vm.$holdTime)
    return axios
      .put(TABLE, _TOKEN(params))
      .then(res => {
        return !res.data.errors
      })
      .catch(err => {
        return false
      })
  },

  setSessionData({ commit }, data) {
    return commit("setSessionData", data)
  },
}

// mutations
const mutations = {
  setSessionData(state, data) {
    if (data) {
      state.user = data
      state.session = true
    } else state.session = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
