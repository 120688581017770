const PRIVATE = `
-----BEGIN RSA PRIVATE KEY-----
MIICXgIBAAKBgQlT4v4tgNAzH9hV/3eBTucpf5PSCsT06A1aVAwWYMQbYECn/8IQ
mpIv+54AewsU6wHKtQ7AD/3PY4IGry3wYkMYiaUJwPRf32gnogJQAuUyBdSM6kHH
vF9ULub9LEGPuPQxQiBzblP0Pmwgz0JT2eT3YT16ksIyN/pngea3fisFSwIDAQAB
AoGAB27Rc7rO6xnSRpJBAPoJ5isrfp/h9REz2XAhiYBLm/o0uVEmfX69rcCOwVmg
szP4EXV20ko6dAbPRM4OyozDwK7u6vmQChYgD2wul2plu+SK0R8MGjSqQhhSQob4
zmv7BfDJK96pgLNrPDOefcQABTHv/7bnZkIiq/jF2W1seqECQQMO8i+lXNDM2ZIZ
YfRdKJ/0aGWtUc9lwfQ5OQgtFSrSP9nMk0kjsS6soRlhyVKL5/ko0r8UCmGhs3j/
6roOpEVFAkEDDMSJMOhQHuwDqTx4hi0lzMRrLFfPN+o5ldyUFBaRFxZSreGtAqOS
N4U7D72Kui2weCxsjuOIYunvNGepA7jhTwJBAraKf1vJF2zxgNpFe/n8JNEynyf+
T+YsBVOR1T/w7BixDaud6J7h+c6n5pVJe5GiyfO96EWF5BAG3srfVgfRD6UCQQIF
1tiTOpHhYHIxLe1WON2ISYI3v+mtUUluTwVbqXgD0wd1UmfYhzq0XkZE81bukt0n
Z3d88gaEbFn97P7ZfCbbAkEBMSrZQS8XDrqYIv7Cd9TaMFki/BYlixXPHptriIPO
uN73HVkiKHHBYFf+FqehGllTOZ752A+i0La6bK8ZmMkSjw==
-----END RSA PRIVATE KEY-----
`

const PUBLIC = `
-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQmhLLOi7oM7DjEENMbDCk2pUdCn
6n5YPNhCw3itaG+M2xVxiRMr/9ty3CGLjF/In4XrDhMeDtl9z01pP8Y3vXTJU0xl
PBrD2CtQpxtKvrx2RRlqlGJ6EUucVHclA7M19doRAXDye4iYt1qBKTF0Ajd/01li
guGl0CXS5bQcdIzSDQIDAQAB
-----END PUBLIC KEY-----
`

export { PRIVATE, PUBLIC }
