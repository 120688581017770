import axios from "axios"
import qs from "qs"
import convert from "xml-js"

// initial state
const state = {
  data: []
}

// getters
const getters = {
  getAll: state => {
    return state.data
  }
}

// actions
const actions = {
  get({ commit }, params) {
    return axios({
      method: "POST",
      crossDomain: true,
      async: true,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      data: qs.stringify({
        invocationPassword: params.pwd,
        strcabid: params.orcamento
      }),
      url: `${params.url}/DrublocGetTenderLines`
    }).then(res => {
      return JSON.parse(
        JSON.parse(
          convert.xml2json(res.data, {
            compact: true,
            spaces: 4
          })
        ).string._text
      )
    })
  }
}

// mutations
const mutations = {
  setData(state, data) {
    state.data = data ? data : []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
