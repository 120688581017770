import axios from "axios"
import qs from "qs"
import convert from "xml-js"

// initial state
const state = {
  data: [],
  loading: false
}

// getters
const getters = {
  getAll: state => {
    return state.data
  },
  IsLoading: state => {
    return state.loading
  },
  getById: state => id => {
    if (
      !id ||
      state.data.length === 0 ||
      typeof state.data.find(el => {
        return el.clientecodigo === id
      }) == "undefined"
    )
      return "-"
    return state.data.find(el => {
      return el.clientecodigo === id
    }).nome
  },
  getCliente: state => id => {
    if (
      !id ||
      state.data.length === 0 ||
      typeof state.data.find(el => {
        return el.clientecodigo === id
      }) == "undefined"
    )
      return "-"
    return state.data.find(el => {
      return el.clientecodigo === id
    })
  }
}

// actions
const actions = {
  get({ commit }, params) {
    commit("setLoading", true)
    axios({
      method: "POST",
      crossDomain: true,
      async: true,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      data: qs.stringify({
        invocationPassword: params.pwd,
        type: "C"
      }),
      url: `${params.url}/DrublocGetEntities`
    }).then(res => {
      var result = JSON.parse(
        JSON.parse(
          convert.xml2json(res.data, {
            compact: true,
            spaces: 4
          })
        ).string._text
      )
      commit("setData", result)
      commit("setLoading", false)
    })
  }
}

// mutations
const mutations = {
  setData(state, data) {
    state.data = data ? data : []
  },
  setLoading(state, st) {
    state.loading = st
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
