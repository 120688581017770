<template>
  <v-app v-if="this.auth == 1" id="inspire">
    <v-dialog style="z-index: 550 !important;" v-model="loader" persistent width="400">
      <v-card color="white" class="pl-5 pr-5 pt-3">
        <v-card-text>
          Esta operação pode demorar algum tempo.
          <br />Por favor aguarde...
          <v-progress-linear indeterminate :color="$primary" class="mb-0 mt-3"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <CHANGEPWD />

    <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" v-model="drawer" fixed app>
      <v-list id="side_menu" dense>
        <template v-for="item in MENU">
          <v-subheader v-if="typeof item.subheader != 'undefined'">{{ item.text }}</v-subheader>
          <v-list-item
            v-if="
              $_HASPERMISSION(item.name, getSession.user.id_tipo_utilizador)
            "
            :key="item.text"
            :to="item.to"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <div v-if="$vuetify.breakpoint.lgAndUp" class="logo-bar">
          <img :src="require('@/assets/logo.png')" />
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" :color="$primary" dark app fixed>
      <v-toolbar-title style="width: 600px" class="ml-0 pl-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">Drubloc</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="wlc">{{ $t("messages.welcome") }}, {{ getSession.user.nome }}</span>
      <span class="divi">|</span>
      <v-btn v-on:click.stop="personalData" icon>
        <v-icon>settings</v-icon>
      </v-btn>
      <v-btn v-on:click.stop="logout" icon>
        <v-icon>power_settings_new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content id="main_content">
      <v-container fluid fill-height>
        <v-row>
          <router-view></router-view>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
  <Login v-else-if="this.auth == 0" />
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"

import { _TOKENLOGIN, _DECODELOGIN } from "@/store/_JWT"
import MENU from "@/mixins/_MENU"

import "./style.scss"

export default {
  mixins: [MENU],
  components: {
    CHANGEPWD: () => import("@/components/dialogs/ChangePwd")
  },
  data: () => ({
    logo: require("@/assets/logo.png"),
    auth: -1,
    drawer: null,
    loader: false
  }),
  computed: {
    ...mapGetters({
      getSession: "session/getState"
    })
  },
  watch: {},
  created() {
    //this.OpenStore();
    this.ManageSession()

    this.$eventBus.$on("loader", state => {
      this.loader = state
    })

    this.$eventBus.$on("login", res => {
      if (res) {
        this.SessionStart()
      }
    })
    this.$eventBus.$on("logout", () => {
      this.logout()
    })
  },
  methods: {
    personalData() {
      this.$eventBus.$emit("ChangePwdDialog", true)
    },

    ManageSession() {
      if (!this.$session.exists()) this.auth = 0
      else {
        _DECODELOGIN(this.$cookies.get("t")).then(res => {
          this.$store
            .dispatch("session/getData", {
              username: res
            })
            .then(() => {
              if (this.getSession.session) {
                this.SessionStart()
              } else this.auth = 0
            })
        })
      }
    },

    SessionStart() {
      this.$session.start()
      this.$cookies.config("3d")
      this.$cookies.set("t", _TOKENLOGIN(this.getSession.user.username))
      this.$toast.success(
        `${this.$t("messages.welcome")}, ${this.getSession.user.username}`
      )
      this.BaseStore()
      this.OpenStore()
    },

    async OpenStore() {
      this.auth = 1
    },

    async BaseStore() {
      this.$store.dispatch("tipo_utilizador/get")
      this.$store.dispatch("utilizador/get")
      this.$store.dispatch("at/get")
      this.$store.dispatch("at/getAllClients")
      this.$store.dispatch("agenda/get")
      this.$store.dispatch("obra/get")
      this.$store.dispatch("tipo_at/get")
      this.$store.dispatch("prioridade/get")
      this.$store.dispatch("localidade/getAllDistritos")
      this.$store.dispatch("cliente/get", {
        url: this.$serviceUrl,
        pwd: this.$servicePwd
      })
    },

    logout() {
      this.$store.dispatch("session/setSessionData", null).then(() => {
        this.auth = 0
        this.$session.destroy()
      })
    }
  }
}
</script>
<style scoped>
.v-toolbar__content,
.v-toolbar__title {
  display: flex !important;
  align-items: center !important;
}

.wlc {
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
}

.divi {
  font-size: 25px !important;
  font-weight: 400 !important;
  margin: 0px 10px 2px 20px !important;
}
</style>
