import JWT from "jsonwebtoken"
import { PRIVATE, PUBLIC } from "@/store/_VARIABLES"

const exp = 60
const expLogin = 3600 * 12

const date = new Date();
const secret = `${process.env.VUE_APP_SECRET}#${date.getFullYear()}${date.getMonth()}${date.getDate()}`

const _DECODE = token => {
  return new Promise(function(resolve, reject) {
    JWT.verify(token, PUBLIC, (err, decoded) => {
      if (err) resolve(false)
      else resolve(JWT.decode(token).data)
    })
  })
}

const _TOKEN = params => {
  return {
    data: JWT.sign(
      {
        exp: Math.floor(Date.now() / 1000) + exp,
        data: params
      },
      PRIVATE,
      { algorithm: "RS256" }
    )
  }
}

const _DECODELOGIN = token => {
  return new Promise(function(resolve, reject) {
    JWT.verify(token, secret, (err, decoded) => {
      if (err) resolve(false)
      else resolve(JWT.decode(token).data)
    })
  })
}

const _TOKENLOGIN = params => {
  return JWT.sign(
    {
      exp: Math.floor(Date.now() / 1000) + expLogin,
      data: params
    },
      secret
  )
}

export { _DECODE, _TOKEN, _TOKENLOGIN, _DECODELOGIN }
