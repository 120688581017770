import Vue from "vue"
import VueRouter from "vue-router"
import Login from "@/views/Login"

Vue.use(VueRouter)

Vue.component("Login", Login)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/administrador",
    name: "administrador",
    component: () => import("@/views/Administrador.vue")
  },
  {
    path: "/colaborador",
    name: "colaborador",
    component: () => import("@/views/Colaborador.vue")
  },
  {
    path: "/at",
    name: "at",
    component: () => import("@/views/AT.vue")
  },
  {
    path: "/tipo_at",
    name: "tipo_at",
    component: () => import("@/views/Tipo_AT.vue")
  },
  {
    path: "/prioridade",
    name: "prioridade",
    component: () => import("@/views/Prioridade.vue")
  },
  {
    path: "/cliente",
    name: "cliente",
    component: () => import("@/views/Cliente.vue")
  },
  {
    path: "/obra",
    name: "obra",
    component: () => import("@/views/Obra.vue")
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
