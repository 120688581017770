import { instance as axios } from "@/store/_AXIOS"

const TABLE = "/localidade/"

// initial state
const state = {
    distritos: [],
    defaultConcelhos: [],
    concelhos: []
}

// getters
const getters = {
    getAll: state => {
        return state.data
    },
    getDistrito: state => id => {
        if (
            !id ||
            state.distritos.length === 0 ||
            typeof state.distritos.find(el => {
                return el.id_distrito === id
            }) == "undefined"
        )
            return "-"
        return state.distritos.find(el => {
            return el.id_distrito === id
        }).nome
    },
    getDistritos(state) {
        return state.distritos
    },
    getConcelhos(state) {
        return state.concelhos
    },
    getDefault(state) {
        return state.defaultConcelhos
    }
}

// actions
const actions = {
    getAllDistritos({ commit }) {
        return axios
            .get("/distrito/")
            .then(res => {
                return commit("setDistritos", res.data.results > 0 ? res?.data?.data : []);
            })
            .catch(err => {
                commit("setDistritos", null)
            })
    },

    getConcelhos({ commit }, params) {
        return axios
            .post("/concelho/params/", {data:params})
            .then(res => {
                return res.data.results > 0 ? res?.data?.data : []
            })
            .catch(err => {
                return null
            })
    },

    getLocalidades({ commit }, params) {
        return axios
            .post("/localidade/params/", {data:params})
            .then(res => {
                return res.data.results > 0 ? res?.data?.data : []
            })
            .catch(err => {
                return null
            })
    }
}

// mutations
const mutations = {
    setDistritos(state, distritos) {
        state.distritos = distritos ? distritos : []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

