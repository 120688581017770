import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import VueI18n from "vue-i18n"
import vuetify from "./plugins/vuetify"
import Moment from "moment"
//import Moment from "moment/src/moment"
import VueSession from "vue-session"
import IdleVue from "idle-vue"
import VueIziToast from "vue-izitoast"
import "izitoast/dist/css/iziToast.min.css"
import _BUGSNAG from "./libs/_BUGSNAG"
import _I18N from "./store/_I18N"
import {
  _HASPERMISSION,
  _ISALLOWED,
  _ISDISABLED,
  _ISSUPER
} from "./store/_PERMISSIONS"
import VueCookies from "vue-cookies"
Vue.config.productionTip = false

// -- GLOBAL VARIABLES

Vue.prototype.$eventBus = new Vue()
Vue.prototype.$toastPosition = "topRight"
Vue.prototype.$toastTimeout = "3000"
Vue.prototype.$loadTimeout = 1000
Vue.prototype.$files = process.env.VUE_APP_API + "/files/"
Vue.prototype.$imagemsUrl = process.env.VUE_APP_API + "/images"
Vue.prototype.$moment = Moment
Vue.prototype.$moment.locale("pt")
Vue.prototype.$holdTime = 1000

Vue.prototype.$serviceUrl =
  process.env.NODE_ENV == "development"
    ? "http://drubloc.dnsfor.me:8101/webservicedrubloc/drublocservice.asmx"
    : "http://drubloc.dnsfor.me:8101/webservicedrubloc/drublocservice.asmx"
Vue.prototype.$servicePwd = "!1n0v3"

Vue.prototype.$_HASPERMISSION = _HASPERMISSION
Vue.prototype.$_ISALLOWED = _ISALLOWED
Vue.prototype.$_ISDISABLED = _ISDISABLED
Vue.prototype.$_ISSUPER = _ISSUPER
Vue.prototype.$_BUGSNAG = _BUGSNAG

// -- DEPENDENCIES

var options = {
  persist: true
}
Vue.use(VueSession, options)

const eventBus = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventBus,
  idleTime: 3600000
})

Vue.use(VueIziToast)
Vue.use(VueCookies)
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: "pt",
  messages: _I18N
})

// -- COLORS

Vue.prototype.$primary = "#222222"

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  onIdle() {
    this.$eventBus.$emit("logout")
  }
}).$mount("#app")
