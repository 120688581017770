// 1 - Inove  2 - Administrador  3 - Utilizador  4 - Utente
const _GROUPS = {
  super: [1],
  only_super: [1, 2]
}

const _COMPONENTS = {
  home: _GROUPS.only_super,
  administrador: _GROUPS.only_super,
  colaborador: _GROUPS.only_super,
  at: _GROUPS.only_super,
  tipo_at: _GROUPS.only_super,
  prioridade: _GROUPS.only_super,
  cliente: _GROUPS.only_super,
  obra: _GROUPS.only_super
}

const _HASPERMISSION = (component, type) => {
  if (!type || !component) return false

  return (
    typeof _COMPONENTS[component] != "undefined" &&
    _COMPONENTS[component].findIndex(el => {
      return el == Number(type)
    }) > -1
  )
}

const _ISALLOWED = (group, type) => {
  if (!type || !group) return false

  return (
    typeof group != "undefined" &&
    group.findIndex(el => {
      return el == Number(type)
    }) > -1
  )
}

const _ISDISABLED = (group, type) => {
  if (!type || !group) return false

  return (
    typeof group != "undefined" &&
    group.findIndex(el => {
      return el == Number(type)
    }) > -1
  )
}

const _ISSUPER = type => {
  if (!type) return false

  return (
    _GROUPS.super.findIndex(el => {
      return el == Number(type)
    }) > -1
  )
}

export { _GROUPS, _HASPERMISSION, _ISALLOWED, _ISDISABLED, _ISSUPER }
