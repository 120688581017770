import { instance as axios } from "@/store/_AXIOS"

const TABLE = "/intervencao/"
let isClicked = false

// initial state
const state = {
  data: []
}

// getters
const getters = {
  getAll: state => {
    return state.data
  }
}

// actions
const actions = {
  get({ commit }, params) {
    return axios
      .post(TABLE + "params/", {data: params})
      .then(res => {
        return res?.data?.data || [];
        // return _DECODE(res.data.data).then(r => {
        //   if (r) return r
        //   else return []
        // })
      })
      .catch(err => {
        return []
      })
  }
}

// mutations
const mutations = {
  setData(state, data) {
    state.data = data ? data : []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
