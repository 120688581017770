import Vue from "vue"
import Vuex from "vuex"
import session from "./modules/session"
import utilizador from "./modules/utilizador"
import tipo_utilizador from "./modules/tipo_utilizador"
import at from "./modules/at"
import tipo_at from "./modules/tipo_at"
import prioridade from "./modules/prioridade"
import cliente from "./modules/cliente"
import orcamento from "./modules/orcamento"
import linha from "./modules/linha"
import obra from "./modules/obra"
import agenda from "./modules/agenda"
import intervencao from "./modules/intervencao"
import foto from "./modules/foto"
import localidade from "./modules/localidade"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    utilizador,
    tipo_utilizador,
    at,
    tipo_at,
    prioridade,
    cliente,
    orcamento,
    linha,
    obra,
    agenda,
    intervencao,
    foto,
    localidade
  },
  strict: false
})
