const pt = {
  menu: {
    menu: "Menu",
    settings: "Definições",
    home: "Home",
    obra: "Obra",
    cliente: "Clientes",
    administrador: "Administradores",
    colaborador: "Colaboradores",
    prioridade: "Prioridades",
    tipo_at: "Tipos de assitência",
    at: "Assistências"
  },
  fields: {
    id: "#",
    username: "Username",
    email: "Email",
    pwd: "Password",
    c_pwd: "Confirmar password",
    name: "Nome",
    status: "Estado",
    actions: "Ações",
    image: "Imagem",
    cliente: "Cliente",
    code: "Código",
    date: "Data",
    hour: "Hora",
    hourstart: "Hora início",
    hourend: "Hora fim",
    tlf: "Telefone",
    tlm: " Telemóvel",
    morada: "Morada",
    localidade: "Localidade",
    cp: "Código-Postal",
    obs: "Descrição",
    prioridade: "Prioridade",
    colaborador: "Colaborador",
    tipo_at: "Tipo assistência",
    preco:"Preço",
    orcamento: "Orçamento",
    orcamentos: "Orçamentos",
    data_agendamento: "Data agendamento",
    data_criacao: "Data criação",
    days: "Dias",
    data_maximo: "Data máxima",
    desde: "Agendamento desde",
    ate: "Agendamento até",
    state: "Estado",
    distrito: "Distrito",
    concelho: "Concelho",
    freguesia: "Freguesia"
  },
  actions: {
    cancel: "Cancelar",
    confirm: "Confirmar",
    signin: "Entrar",
    search: "Pesquisar",
    active: "Ativo",
    inactive: "Inativo",
    pendente: "Pendente",
    agendada: "Agendada",
    concluida: "Concluída",
    paga: "Paga",
    filtrar: "Filtrar",
    limpar: "Limpar"
  },
  messages: {
    success: "Operação realizada com sucesso!",
    error: "Não foi possível realizar a operação!",
    welcome: "Bem-vindo",
    allrights: "Todos os direitos reservados",
    itemPerPage: "Items por página:",
    all: "Todos",
    datatableNoDataText: "Não existe nada a mostrar",
    datatableNoSearchText: "Não existe nada que corresponda à sua pesquisa",
    nologin: "O username ou a password estão incorretos!",
    semOrcamentos: "Sem orçamentos a apresentar",
    carregarOrcamentos: "A carregar orçamentos para este cliente..."
  },
  rules: {
    pwd_match: "* As passwords não coincidem",
    required: "* Campo obrigatório",
    email: "* E-mail inválido",
    isNumber: "* Valor deve ser numérico",
    isFloat: "* Valor deve ser numérico"
  },
  datatables: {
    administrador: {
      TableTitle: "Administradores",
      ModalNewText: "Novo administrador",
      ModalUpdateText: "Alterar administrador",
      DeleteDialogTitle: "Apagar administrador",
      ModalDeleteText: "Pretende apagar este administrador?"
    },
    colaborador: {
      TableTitle: "Colaboradores",
      ModalNewText: "Novo colaborador",
      ModalUpdateText: "Alterar colaborador",
      DeleteDialogTitle: "Apagar colaborador",
      ModalDeleteText: "Pretende apagar este colaborador?"
    },
    at: {
      TableTitle: "Assistências",
      ModalNewText: "Nova assistência",
      ModalUpdateText: "Alterar assistência",
      DeleteDialogTitle: "Apagar assistência",
      ModalDeleteText: "Pretende apagar esta assistência?"
    },
    prioridade: {
      TableTitle: "Prioridades",
      ModalNewText: "Nova prioridade",
      ModalUpdateText: "Alterar prioridade",
      DeleteDialogTitle: "Apagar prioridade",
      ModalDeleteText: "Pretende apagar este prioridade?"
    },
    tipo_at: {
      TableTitle: "Tipos assistência",
      ModalNewText: "Novo tipo assistência",
      ModalUpdateText: "Alterar tipo assistência",
      DeleteDialogTitle: "Apagar tipo assistência",
      ModalDeleteText: "Pretende apagar este tipo assistência?"
    },
    cliente: {
      TableTitle: "Cliente",
      ModalNewText: "Novo cliente",
      ModalUpdateText: "Alterar cliente",
      DeleteDialogTitle: "Apagar cliente",
      ModalDeleteText: "Pretende apagar este cliente?"
    },
    obra: {
      TableTitle: "Obra",
      ModalNewText: "Nova obra",
      ModalUpdateText: "Alterar obra",
      DeleteDialogTitle: "Apagar obra",
      ModalDeleteText: "Pretende apagar esta obra?"
    }
  }
}

export default pt
