import axios from "axios"

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    'X-Cookie': `${process.env.VUE_APP_SECRET}#${new Date().toISOString().split('T')[0].replaceAll("-","")}`
  }
})

export { instance }
