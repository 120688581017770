import { instance as axios } from "@/store/_AXIOS"

const TABLE = "/agenda/"
let isClicked = false

// initial state
const state = {
  data: []
}

// getters
const getters = {
  getAll: state => {
    return state.data
  },
  getById: state => id => {
    if (
      !id ||
      state.data.length === 0 ||
      typeof state.data.find(el => {
        return el.id_agenda === id
      }) == "undefined"
    )
      return "-"
    return state.data.find(el => {
      return el.id_agenda === id
    }).nome
  }
}

// actions
const actions = {
  get({ commit }, params) {
    return axios
      .get(TABLE)
      .then(res => {
        return commit("setData", res?.data?.data || null);
        // _DECODE(res.data.data).then(r => {
        //   if (r) commit("setData", r)
        //   else commit("setData", null)
        // })
      })
      .catch(err => {
        commit("setData", null)
      })
  },

  insert({ dispatch, rootState }, params) {
    if (isClicked) return undefined
    isClicked = true

    setTimeout(() => {
      isClicked = false
    }, this._vm.$holdTime)
    return axios
      .post(TABLE, {data: params})
      .then(res => {
        dispatch("get")

        return !res.data.errors
      })
      .catch(err => {
        return false
      })
  },

  update({ dispatch, rootState }, params) {
    if (isClicked) return undefined
    isClicked = true

    setTimeout(() => {
      isClicked = false
    }, this._vm.$holdTime)
    return axios
      .put(TABLE, {data: params})
      .then(res => {
        dispatch("get")

        return !res.data.errors
      })
      .catch(err => {
        return false
      })
  },

  delete({ dispatch, rootState }, params) {
    if (isClicked) return undefined
    isClicked = true

    setTimeout(() => {
      isClicked = false
    }, this._vm.$holdTime)
    return axios
      .delete(TABLE + params.id)
      .then(res => {
        dispatch("get")

        return !res.data.errors
      })
      .catch(err => {
        return false
      })
  }
}

// mutations
const mutations = {
  setData(state, data) {
    state.data = data ? data : []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
