const MENU = {
  computed: {
    MENU() {
      return [
        {
          subheader: true,
          text: this.$t("menu.menu")
        },
        {
          icon: "home",
          name: "home",
          text: this.$t("menu.home"),
          to: "/"
        },
        {
          icon: "mdi-truck-fast",
          name: "at",
          text: this.$t("menu.at"),
          to: "/at"
        },
        {
          icon: "mdi-home-map-marker",
          name: "obra",
          text: this.$t("menu.obra"),
          to: "/obra"
        },
        {
          icon: "mdi-account-multiple",
          name: "at",
          text: this.$t("menu.cliente"),
          to: "/cliente"
        },
        {
          icon: "mdi-account-check",
          name: "colaborador",
          text: this.$t("menu.colaborador"),
          to: "/colaborador"
        },
        {
          icon: "mdi-account-star",
          name: "administrador",
          text: this.$t("menu.administrador"),
          to: "/administrador"
        },
        {
          subheader: true,
          text: this.$t("menu.settings")
        },
        {
          icon: "mdi-format-list-bulleted-type",
          name: "tipo_at",
          text: this.$t("menu.tipo_at"),
          to: "/tipo_at"
        },
        {
          icon: "mdi-priority-high",
          name: "prioridade",
          text: this.$t("menu.prioridade"),
          to: "/prioridade"
        }
      ]
    }
  }
}

export default MENU
