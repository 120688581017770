import Vue from "vue"
import bugsnag from "@bugsnag/js"
import bugsnagVue from "@bugsnag/plugin-vue"

const _BUGSNAG = bugsnag("4263d5abcb9ab028a6ef514c0ad9e452").use(
  bugsnagVue,
  Vue
)

export default _BUGSNAG
