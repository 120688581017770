const RULES = {
  computed: {
    RULES() {
      return {
        required: v => !!v || this.$t("rules.required"),
        c_pwd: v => v == this.password || this.$t("rules.pwd_match"),
        match_pwd: v =>
          v == this.MyObject.password || this.$t("rules.pwd_match"),
        email: v =>
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            v
          ) || this.$t("rules.email"),
        isNumber: v =>
          !!Number.isInteger(Number(v)) || this.$t("rules.isNumber"),
        isFloat: v => !!!Number.isNaN(parseFloat(v)) || this.$t("rules.isFloat")
      }
    }
  }
}

export default RULES
